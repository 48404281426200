
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import * as bootstrap from "bootstrap";
import ListingTokens from "../Tokens/ListingTokens.vue";

export default defineComponent({
  name: "LandingPage",
  props: {},
  components: { ListingTokens },

  data() {
    return {
      selectedGroup: "",
      templates: [] as any[],
      promptsList: [] as any[],
      promptsTypeList: [] as any[],
      isError: false,
      templateName: "",
      templateText: "",
      deleteModal: bootstrap.Modal,
      hideUnhideModal: bootstrap.Modal,
      shareNoteModal: bootstrap.Modal,
      templateToDelete: false,
      addPrompModal: bootstrap.Modal,
      searchWord: "",
      groups: [] as any[],
      templatesToShow: [] as any[],
    };
  },
  async mounted() {
    let modalElement = document.getElementById("deleteTemplate_modal");
    this.deleteModal = new bootstrap.Modal(modalElement);
    if (modalElement) {
      var self = this;
      modalElement.addEventListener("hidden.bs.modal", function (event) {
        self.templateToDelete = false;
      });
    }
    modalElement = document.getElementById("add_prompt_modal");
    this.addPrompModal = new bootstrap.Modal(modalElement);

    modalElement = document.getElementById("hide_unhide_templates_modal");
    this.hideUnhideModal = new bootstrap.Modal(modalElement);

    modalElement = document.getElementById("share_note_modal");
    this.shareNoteModal = new bootstrap.Modal(modalElement);

    await this.getAllTemplates();
    await this.getAllTokens();
    await this.getAllTokenTypes();
    this.filterTemplatesBySpecificGroup(this.groups[0] || "");
  },
  methods: {
    async deleteGroup(group: any){
      let res = await ApiService.delete("Template/Group/" + group.templateGroupId);
      if (res.status === 200) {
        const index = this.groups.indexOf(group);
        if (index > -1) {
          this.groups.splice(index, 1);
        }        
        this.deleteModal.hide();
      } else {
        alert("Something wen wrong while deleting groups.");
      }
    },
    openShareNoteModal() {
      this.shareNoteModal.show();
    },
    closeShareNoteModal() {
      this.shareNoteModal.hide();
    },
    shareNote() {
      this.openShareNoteModal();
    },

    openHideUnhideModal() {
      this.hideUnhideModal.show();
    },

    closeHideUnhideModal() {
      this.hideUnhideModal.hide();
    },
    async hideUnHideTemplate(template_id, hidden) {
      let res = await ApiService.put(`Template/${template_id}/${hidden}`, null, null as any);
      if (res.status === 200) {
        console.log(`${template_id} is ${hidden} hidden`);
        await this.getAllTemplates();
        let temp = this.templates.find((x) => x.templateId === template_id);
        let grp = this.groups.find(x=> x.templateGroupId === temp.templateGroupId);
        this.filterTemplatesBySpecificGroup(grp);
      }
    },  
    filterTemplatesBySpecificGroup(grp) {
      this.selectedGroup = grp.name;
      this.templatesToShow = [];
      for (let i = 0 ; i < this.templates.length; i++){
        let elt = this.templates[i];
        if(elt.templateGroupId  === grp.templateGroupId){
          this.templatesToShow.push(elt);
        }        
      }      
    },
    openTemplate(name) {      
      this.$router.push({
        name: `NewNote`,
        params: { name: name },
      });
    },
    openPromptModal() {
      this.addPrompModal.show();
    },
    closePromptModal() {
      this.addPrompModal.hide();
    },
    handleEdit(event, template) {
      event.stopImmediatePropagation();      
      this.$router.push({ path: `/notes/templates/${template.templateId}` });
    },    
    async getAllTemplates() {
      let res = await ApiService.get("Template");
      if (res.status === 200) {        
        this.templates = res.data;        
      }
      
      res = await ApiService.get("Template/Group");
      if (res.status === 200) {        
        this.groups = res.data;
        for(let g of this.groups){
          g.hasNoTemplates = this.templates.filter(t => t.templateGroupId === g.templateGroupId).length === 0;
        }
      }

    },
    async getAllTokens() {
      await ApiService.get("Token").then((res) => {
        if (res) {
          this.promptsList = res.data;
        }
      });
    },
    async getAllTokenTypes() {
      await ApiService.get("TokenType").then((res) => {
        if (res) {
          this.promptsTypeList = res.data;
        }
      });
    },
    openDeleteModal(event, template) {
      event.stopImmediatePropagation();
      this.deleteModal.show();
      this.templateToDelete = template;
    },
    async deleteTemplate(templateId) {
      let res = await ApiService.delete("Template/" + templateId);
      if (res.status === 200) {
        await this.getAllTemplates();
        this.deleteModal.hide();
      } else {
        alert("Something wen wrong while deleting template.");
      }
    },
    searchForTemplateByName(e) {
      let target = e.target.value.toLowerCase();
      let filteredTemplates = [] as any[];
      if (this.searchWord == "") {
        return;
      }
      this.templates.forEach((template) => {
        if (
          template.name.toLowerCase().includes(`${target}`) ||
          template.text.toLowerCase().includes(`${target}`)
        ) {
          filteredTemplates.push(template);
        }
      });
      this.templatesToShow = filteredTemplates;
    },
  },
  computed: {
    searchTemplates: function () {
      let filteredTemplates = [] as any[];
      if (this.searchWord == "") {
        return this.templates;
      }
      this.templates.forEach((template) => {
        if (
          template.name
            .toLowerCase()
            .includes(`${this.searchWord.toLowerCase()}`) ||
          template.text
            .toLowerCase()
            .includes(`${this.searchWord.toLowerCase()}`)
        ) {
          filteredTemplates.push(template);
        }
      });
      return filteredTemplates;
    },
  },
  setup() {
    setCurrentPageTitle("GoatNotes");
  },
});
