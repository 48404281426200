
import { defineComponent } from "vue";

export default defineComponent({
  name: "avatar",
  props: {
    avatarSize: { type: Number, default: 3 },
    initials: { type: Object, default: null },
  },
  computed: {
    divisor() {
      let user = this.$store.getters.currentUser;
      if (this.initials) {
        return (this.initials.firstName + " " + this.initials.lastName).split(" ").length;
      } else if (user) {
        return (user.firstName + " " + user.lastName).split(" ").length;
      }
    },
    initialsObject() {
      if (this.initials) {
        let nameChars = (this.initials.firstName + " " + this.initials.lastName).split(" ");
        return {
          initialsName: nameChars
            .map((x) => x.charAt(0).toUpperCase())
            .join(""),
          fullName: this.initials.firstName + " " + this.initials.lastName,
          avatarColor: this.initials.avatarColor,
        };
      }
    },
    userInfo(): any {
      let user = this.$store.getters.currentUser;

      if (user) {
        let currentUserInfo = {
          initials:
            user.firstName.charAt(0).toUpperCase() +
            user.lastName.charAt(0).toUpperCase(),
          fullName: user.firstName + " " + user.lastName,
          avatarColor: "#0078D7",
        };

        if (user.preferences) {
          let prefs = JSON.parse(user.preferences);
          if (prefs.avatarColor) {
            currentUserInfo.avatarColor = prefs.avatarColor;
          }
        }
        return currentUserInfo;
      } else {
        return null;
      }
    },
  },
});
