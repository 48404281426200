import ApiService from "@/core/services/ApiService";
import { Mutations } from "@/store/enums/StoreEnums";

export class UserContext {
  route;
  store;
  /**
   *
   */
  constructor(route, store) {
    this.route = route;
    this.store = store;
  }

  async buildCurrentUser() {
    let currentUser:any;
    try {
      currentUser = await ApiService.get("user/current");
    } catch (e: any) {
      if (e.toString().includes("404")) {
        return false;
      }
    }
    currentUser = currentUser.data;

    let clinicGuid: string | null = null;
    //if no clinic is specified in the URL
    if (this.route.query.c) {
      clinicGuid = this.route.query.c.toString();
    } else {
      clinicGuid = localStorage.getItem("ClinicGuid"); //what if clinic is now found? i'd want to erase this
      if (clinicGuid !== null) {
        if (
          currentUser.clinics.filter((c) => {
            return c.clinicGuid === clinicGuid;
          }).length === 0
        ) {
          clinicGuid = null;
        }
      }
      if (clinicGuid === null) {
        // take the prefereed clinic
        console.log("Prefs " + currentUser.preferences);
        if (currentUser.preferences) {
          const prefs = JSON.parse(currentUser.preferences);
          if (prefs.defaultClinic) {
            clinicGuid = prefs.defaultClinic;
          }
        }
      }
      // take the first clinic
      if (clinicGuid === null) {
        clinicGuid = currentUser.clinics[0].clinicGuid;
      }
    }

    if (currentUser.clinics.length === 0) throw "user has 0 clinics";
    const clinic = currentUser.clinics.filter((c) => {
      return c.clinicGuid === clinicGuid;
    })[0];
    currentUser.currentClinic = clinic;

    document.title = "GoatNotes";
    ApiService.setClinicHeader(clinic.clinicGuid);
    //currentUser.otherUsers = await (await ApiService.get("user")).data;

    this.store.commit(Mutations.SET_USER, currentUser);
    return true;
  }
}
