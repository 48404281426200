
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import EditToken from "./EditToken.vue";
import * as bootstrap from "bootstrap";
import draggable from "vuedraggable";

export default defineComponent({
  name: "ListingTokens",
  props: {},
  components: {
    EditToken,
    draggable,
  },

  data() {
    return {
      addModal: bootstrap.Modal,
      editModal: bootstrap.Modal,
      deleteModal: bootstrap.Modal,
      tokenToDelete: null,
      tokenfilter: "",
      addTokenVisible: false,
      editTokenVisible: false,
      editTokenModalObject: null,

      tokens: [],
      tokenTypes: [],

      filterSearch: true,
    };
  },
  async mounted() {
    // addTokenType_modal
    let modalElement = document.getElementById("addToken_modal");
    this.addModal = new bootstrap.Modal(modalElement);
    if (modalElement) {
      var self = this;
      modalElement.addEventListener("hidden.bs.modal", function (event) {});
    }

    modalElement = document.getElementById("editToken_modal");
    this.editModal = new bootstrap.Modal(modalElement);
    if (modalElement) {
      var self = this;
      modalElement.addEventListener("hidden.bs.modal", function (event) {});
    }

    modalElement = document.getElementById("deleteToken_modal");
    this.deleteModal = new bootstrap.Modal(modalElement);
    if (modalElement) {
      var self = this;
      modalElement.addEventListener("hidden.bs.modal", function (event) {});
    }

    await this.getAllTokens();
    await this.getAllTokenTypes();
  },
  computed: {
    filteredTokens: function () {
      let filtered: any[] = [];
      this.tokens.forEach((token: any) => {
        if (
          token.name.toLowerCase().includes(`${this.tokenfilter.toLowerCase()}`)
        ) {
          filtered.push(token);
        }
      });
      // console.log(filtered)
      return filtered.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    },
  },
  methods: {
    toggleFilterSearch() {
      this.filterSearch = !this.filterSearch;
    },
    async getAllTokens() {
      await ApiService.get("Token").then((res) => {
        if (res) {
          this.tokens = this.sort_by_key(res.data, "order");
        }
      });
    },
    async getAllTokenTypes() {
      await ApiService.get("TokenType").then((res) => {
        if (res) {
          this.tokenTypes = res.data;
        }
      });
    },
    sort_by_key(array, key) {
      return array.sort(function (a, b) {
        var x = a[key];
        var y = b[key];
        return x < y ? -1 : x > y ? 1 : 0;
      });
    },
    confirmTokenDeletion(data) {
      this.tokenToDelete = data;
      let modalElement = document.getElementById("deleteToken_modal");
      if (modalElement) {
        modalElement.addEventListener("hidden.bs.modal", function (event) {});
      }
      this.deleteModal = new bootstrap.Modal(modalElement);
      this.deleteModal.show();
    },
    async deleteToken(id) {
      let res = await ApiService.delete(`/Token/${id}`);
      if (res.status === 200) {
        this.deleteModal.hide();
        this.getAllTokens();
      } else {
        alert("Something wen wrong while deleting the package.");
      }
    },
    editedTokenSuccessfully() {
      this.closeAddTokenForm();
      this.closeEditTokenForm();
      this.getAllTokens();
    },
    addToken() {
      this.addModal.show();
      this.addTokenVisible = true;
    },
    changeSelectedToken(token) {
      this.editTokenVisible = true;
      this.editModal.show();
      this.editTokenModalObject = token;
    },
    closeEditTokenForm() {
      this.editModal.hide();
      this.editTokenVisible = false;
    },
    closeAddTokenForm() {
      this.addModal.hide();
      this.addTokenVisible = false;
    },
  },
  setup() {
    // setCurrentPageTitle("");
  },
});
