
import { useStore } from "vuex";

import useValidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import { defineComponent, reactive, computed, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { Models } from "@/Models/Models";
import ListingTokenValues from "../Tokens/ListingTokenValues.vue";

interface FormState {
  name: string;
  tokenTypeId: number;
}

export default defineComponent({
  name: "EditToken",
  props: {
    selectedToken: {
      required: true,
      type: Object,
    },
    tokens: {
      required: true,
      type: Array,
    },
    tokensTypes: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      values: [],
      seperator: "",
    };
  },
  components: {
    ListingTokenValues,
  },
  methods: {
    getNewValues(values) {
      console.log(values);
      this.values = values;
    },
    async submit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        if (this.state.name) {
          let valuesFixed: any = [];
          if (this.values && this.values.length > 0) {
            if ((this.values[0] as any).order == null) {
              for (let i = 0; i < this.values.length; i++) {
                const value = this.values[i];
                valuesFixed.push({ order: i, value: value });
              }
            }
          }
          let payload = {
            name: this.state.name,
            tokenTypeId: this.state.tokenTypeId,
            options: valuesFixed.length > 0 ? valuesFixed : this.values,
            seperator: this.seperator,
          };
          if (this.selectedToken) {
            let currentToken = this.selectedToken as Models.Token;
            let editTokenPayload = {
              tokenId: currentToken.tokenId,
              tokenTypeId: this.state.tokenTypeId,
              name: this.state.name,
              options: valuesFixed.length > 0 ? valuesFixed : this.values,
              seperator: this.seperator,
            };

            payload = editTokenPayload;
          }

          try {
            console.log(payload);
            let editToken = await ApiService.postData("Token", payload, {});

            if (editToken.status === 200) {
              this.$emit("editedTokenSuccessfully");
            }
          } catch (err) {
            console.warn(err);
          }
        }
      }
    },
    discard() {
      this.$emit("closeEditTokenForm");
    },
  },
  updated() {
    if (this.selectedToken) {
      this.allTokens = this.tokens.filter((value) => {
        let val = value as any;
        let selected = this.selectedToken as any;
        return val.name != selected.name;
      });
    }
    let token_to_edit = this.selectedToken as Models.Token;
    if (this.selectedToken && token_to_edit.tokenId != this.tokenId) {
      this.allTokens = this.tokens.filter((value) => {
        let val = value as any;
        let selected = this.selectedToken as any;
        return val.name != selected.name;
      });

      this.tokenId = token_to_edit.tokenId;
      this.state.name = token_to_edit.name;
      this.state.tokenTypeId = token_to_edit.tokenTypeId;
      this.seperator = token_to_edit.seperator;
      console.log(this.$refs.tokenList);
      if (this.$refs.tokenList) {
        (this.$refs.tokenList as any).getAllTokenValues();
      }
    }
  },

  setup(props, context) {
    const store = useStore();
    const initialUser = store.getters.currentUser;
    let seperator = ref("");
    let state: FormState = reactive({
      tokenTypeId: 0,
      name: "",
    });
    let tokenId = 0;
    let allTokens = props.tokens;
    if (props.selectedToken) {
      allTokens = props.tokens.filter((value) => {
        let val = value as any;
        let selected = props.selectedToken as any;
        return val.name != selected.name;
      });
      let token_to_edit = props.selectedToken as Models.Token;
      let stateEdit: FormState = reactive({
        tokenTypeId: token_to_edit.tokenTypeId,
        name: token_to_edit.name,
      });
      state = stateEdit;
      tokenId = token_to_edit.tokenId;
      seperator = ref(token_to_edit.seperator);
    }
    const checkTokenName = (value) => {
      let flag = true;
      allTokens.forEach((val: any) => {
        if (
          val.name.toLowerCase() == value.toLowerCase() &&
          val.tokenId != props.selectedToken.tokenId
        ) {
          flag = false;
          return flag;
        }
      });
      return flag;
    };

    const checkTokenType = (value) => {
      if (value == null || value == "0") {
        return false;
      } else {
        return true;
      }
    };

    const mustBeUniqueName = (value) => checkTokenName(value);
    const mustSelectValue = (value) => checkTokenType(value);

    const rules = computed(() => {
      return {
        name: {
          required: helpers.withMessage("Name is required.", required),
          mustBeUniqueName: helpers.withMessage(
            "Prompt name must be unique.",
            mustBeUniqueName
          ),
        },
        tokenTypeId: {
          required: helpers.withMessage("Prompt Type is required.", required),
          mustSelectValue: helpers.withMessage(
            "Prompt Type must be selected .",
            mustSelectValue
          ),
        },
      };
    });
    const v$ = useValidate(rules, state);

    const closeForm = () => {
      context.emit("closeEditTokenForm");
    };

    return {
      seperator,
      mustBeUniqueName,
      state,
      v$,
      closeForm,
      tokenId,
      allTokens,
      checkTokenName,
      initialUser,
    };
  },
});
