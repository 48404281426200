import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "../store/index";
import KeyPadView from "@/views/KeyPadView.vue";
import Profile from "@/components/Profile/Profile.vue";
import Error500 from "@/views/error/Error500.vue";
import Login from "@/views/Login.vue";
import { defineComponent } from "vue";
import { getInstance } from "@/auth/auth";
import { useAuth0 } from "@/auth/auth";
import { Actions } from "@/store/enums/StoreEnums";
import Setup from "@/components/SetupAccount/Setup.vue";
import SubscriptionPage from "@/components/SetupAccount/SubscriptionPage.vue";
import TokenTypes from "@/components/settings/TokenTypes/TokenType.vue";
import Tokens from "@/components/settings/Tokens/Token.vue";
import EditTemplate from "@/components/settings/Templates/EditTemplate.vue";
import NewNote from "@/components/settings/Notes/Note.vue";
import LandingPage from "@/components/settings/Templates/LandingPage.vue";

const authenticationGuard = (to, from, next) => {
  console.log("guard checking to", to);
  console.log("guard checking from", from);
  console.log("guard checking next", next);
  console.log("guard checking currentuser", store.getters.currentUser);

  if (to.name !== "login" && !store.getters.currentUser) {
    next({ name: "login", query: { redirectFrom: to.fullPath } });
  } else {
    next();
  }
};

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/notes/templates",
    component: () => import("@/layout/Layout.vue"),
    beforeEnter: authenticationGuard,
    children: [
      {
        path: "/profile",
        name: "Profile",
        component: Profile,
      },
      {
        path: "/notes/tokenTypes",
        name: "Token Types",
        beforeEnter: authenticationGuard,
        component: TokenTypes,
      },
      {
        path: "/notes/tokens",
        name: "Tokens",
        beforeEnter: authenticationGuard,
        component: Tokens,
      },
      {
        path: "/notes/templates",
        name: "Templates",
        beforeEnter: authenticationGuard,
        component: LandingPage,
      },
      {
        path: "/notes/templates/:id",
        name: "EditTemplate",
        beforeEnter: authenticationGuard,
        component: EditTemplate,
      },
      {
        path: "/notes/templates/new",
        name: "AddTemplate",
        beforeEnter: authenticationGuard,
        component: EditTemplate,
      },
      {
        path: "/notes/new-note",
        name: "NewNote",
        beforeEnter: authenticationGuard,
        component: NewNote,
      },
    ],
  },
  {
    path: "/setupaccount",
    name: "SetupAccount",
    component: Setup,
  },
  {
    path: "/chooseplan",
    name: "SetupSubscription",
    component: SubscriptionPage,
  },
  {
    path: "/pin",
    name: "Pin",
    component: KeyPadView,
    beforeEnter: authenticationGuard,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/logout",
    name: "logout",
    component: defineComponent({
      async mounted() {
        store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
        localStorage.removeItem("ClinicUserToken");
        if (store.getters.currentUser) {
          await getInstance().logout({ returnTo: window.location.origin });
        } else {
          const domain = "goatnotes.us.auth0.com";
          const clientId = "KYpLoHyXPTyRtwT6Zb1fGpxDfGukCk34";
          const audience = "https://api.goatnotes.ca";

          const auth0 = await useAuth0({
            domain,
            clientId,
            audience,
            redirectUri: `${window.location.origin}/login`,
            onRedirectCallback: (appState) => {
              console.log("appstate", appState);
              this.$router.push(
                appState && appState.targetUrl
                  ? appState.targetUrl
                  : window.location.pathname
              );
            },
          });
          await auth0.logout({ returnTo: window.location.origin });
        }
      },
    }),
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/500",
    name: "Internal Error",
    component: Error500,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters.currentUser) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
