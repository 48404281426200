
	import { defineComponent } from "vue";
	import ApiService from "@/core/services/ApiService";
	import draggable from "vuedraggable";

	export default defineComponent({
		name: "ListingTokenValues",
		props: {
			tokenId: { type: Number },
			tokenTypeId: { type: Number },
		},
		components: { draggable },

		data() {
			return {
				isAdd: false,				
				newValue: "",
				dragging: false,				
				listOfJustTheValues: new Array(),

				valueToEdit: null,
				isEdit: false,
				previousEditValue: null,

				isErrorEmpty: false,
				isErrorUnique: false,

				tokenType6ErrorMultiDefault: { show: false, text: "Dropdown element cannot have more than one pinned value." },

				tokenTypes: new Array(),
				tokenValues: new Array(),
			};
		},
		async mounted() {
			await this.getAllTokenValues();
		},
		methods: {
			GetSortOrder(prop) {
				return function (a, b) {
					if (a[prop] > b[prop]) {
						return 1;
					} else if (a[prop] < b[prop]) {
						return -1;
					}
					return 0;
				};
			},
			checkMove(e) {
				let initialOrder = new Array();
				let order = 0;
				this.listOfJustTheValues.forEach((value) => {
					let foundValue = this.tokenValues.find((x) => x.value === value);
					if (foundValue) {
						foundValue.order = order;
						delete foundValue.clinicId;
						delete foundValue.tokenPossibleValueId;
						delete foundValue.tokenId;
						initialOrder.push(foundValue);
						order++;
					}
				});
				console.log(initialOrder);

				this.$emit("getNewValues", initialOrder);
			},
			async getAllTokenValues() {
				await ApiService.get("TokenPossibleValue").then((res) => {
					if (res) {
						this.tokenValues = res.data.filter((x) => x.tokenId == this.tokenId);
						this.tokenValues = this.tokenValues.sort(this.GetSortOrder("order"));
						this.listOfJustTheValues = this.tokenValues.map((x) => x.value);
						this.$emit("getNewValues", this.listOfJustTheValues);
					}
				});
			},
			toggleAdd() {
				this.isAdd = !this.isAdd;
			},
			async saveValue() {
				this.newValue == null || this.newValue == "" ? (this.isErrorEmpty = true) : (this.isErrorEmpty = false);

				if (this.newValue != null && this.newValue != "") {
					this.checkValueName() ? (this.isErrorUnique = true) : (this.isErrorUnique = false);
				}

				if (!this.isErrorEmpty && !this.isErrorUnique) {
					console.log(this.tokenValues);
					this.listOfJustTheValues.push(this.newValue);
					this.tokenValues.push({
						tokenId: 0,
						tokenTypeId: 0,
						value: this.newValue,
						order: 0,
						isDefault: false,
						isNone: false
					});
					this.$emit("getNewValues", this.listOfJustTheValues);
					this.newValue = "";
					this.isAdd = !this.isAdd;
				}
			},
			async deleteValue(value) {
				this.listOfJustTheValues.splice(this.listOfJustTheValues.indexOf(value), 1);
				this.$emit("getNewValues", this.listOfJustTheValues);				
			},
			async pinTokenValue(token) {
				this.tokenType6ErrorMultiDefault.show = false;

				console.log(this.tokenValues);
				let initialOrder = new Array();
				let order = 0;
				let itemToPin = null;
				let isDef = this.tokenValues.find((x) => x.isDefault == true);
				if (isDef != null && isDef.value.toLowerCase() != token.toLowerCase() && this.tokenTypeId == 6) {
					// this.tokenType6ErrorMultiDefault.show = true;
					// return;
					this.tokenValues.find((x) => x.isDefault == true).isDefault = false;
				}
				this.listOfJustTheValues.forEach((value) => {
					let foundValue = this.tokenValues.find((x) => x.value.toLowerCase() === value.toLowerCase());
					if (foundValue) {
						if (foundValue.value.toLowerCase() == token.toLowerCase()) {
							foundValue.isDefault = !foundValue.isDefault;
						}
						foundValue.order = order;
						delete foundValue.clinicId;
						delete foundValue.tokenPossibleValueId;
						delete foundValue.tokenId;
						// itemToPin = foundValue;
						initialOrder.push(foundValue);
						order++;
					}
				});
				console.log(initialOrder);
				// let x = await ApiService.postData("/TokenPossibleValue", itemToPin, {});
				// console.log(x);
				this.$emit("getNewValues", initialOrder);
			},
			async setAsNoneTokenValue(token) {
				this.tokenType6ErrorMultiDefault.show = false;

				console.log(this.tokenValues);
				let initialOrder = new Array();
				let order = 0;
				let itemToPin = null;
				let isDef = this.tokenValues.find((x) => x.isNone == true);
				if (isDef != null && isDef.value.toLowerCase() != token.toLowerCase()) {
					// this.tokenType6ErrorMultiDefault.show = true;
					// return;
					this.tokenValues.find((x) => x.isNone == true).isNone = false;
				}
				this.listOfJustTheValues.forEach((value) => {
					let foundValue = this.tokenValues.find((x) => x.value.toLowerCase() === value.toLowerCase());
					if (foundValue) {
						if (foundValue.value.toLowerCase() == token.toLowerCase()) {
							foundValue.isNone = !foundValue.isNone;
						}
						foundValue.order = order;
						delete foundValue.clinicId;
						delete foundValue.tokenPossibleValueId;
						delete foundValue.tokenId;
						// itemToPin = foundValue;
						initialOrder.push(foundValue);
						order++;
					}
				});
				console.log(initialOrder);
				// let x = await ApiService.postData("/TokenPossibleValue", itemToPin, {});
				// console.log(x);
				this.$emit("getNewValues", initialOrder);
			},
			async editValue(value) {
				console.log(value);
				console.log("valuesList: ", this.listOfJustTheValues);
				console.log("tokenValues: ", this.tokenValues);
				// let payload = value;
				// payload.tokenTypeId = this.tokenTypeId;
				// let res = await ApiService.postData("TokenPossibleValue", payload, {});
				// console.log(payload);
				this.listOfJustTheValues[this.listOfJustTheValues.indexOf(this.previousEditValue)] = value.value;
				for (let i = 0; i < this.tokenValues.length; i++) {
					const token = this.tokenValues[i] as any;
					if (token.value.toLowerCase() == (this.previousEditValue as any).toLowerCase()) {
						this.tokenValues[i] = value;
					}
				}
				this.$emit("getNewValues", this.listOfJustTheValues);
				this.isEdit = false;
			},
			showEditModal(value) {
				this.isEdit = true;
				this.valueToEdit = this.tokenValues.find((x) => x.value.toLowerCase() == value.toLowerCase());
				this.previousEditValue = (this.valueToEdit as any).value;
				console.log("value to edit", this.valueToEdit);
			},
			cancelEditValue() {
				this.valueToEdit = null;
				this.isEdit = false;
			},
			checkValueName() {
				// debugger;
				let flag = false;
				this.listOfJustTheValues.forEach((val: any) => {
					if (val.toLowerCase() == this.newValue.toLowerCase()) {
						flag = true;
						return flag;
					}
				});
				return flag;
			},
		},
	});
