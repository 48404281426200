
import { useStore } from "vuex";

import useValidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import { defineComponent, reactive, computed } from "vue";
import ApiService from "@/core/services/ApiService";
import { Models } from "@/Models/Models";

interface FormState {
  tokenTypeId: number;
  tokenTypeName: string;
}

export default defineComponent({
  name: "EditTokenType",
  props: {
    selectedTokenType: {
      required: true,
    },
    tokenTypes: {
      required: true,
      type: Array,
    },
  },
  components: {},
  methods: {
    async submit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        if (this.state.tokenTypeName) {
          let payload = {
            tokenTypeId: this.state.tokenTypeId,
            tokenTypeName: this.state.tokenTypeName,
          };
          if (this.selectedTokenType) {
            let currentTokenType = this.selectedTokenType as Models.TokenType;
            let editTokenTypePayload = {
              tokenTypeId: currentTokenType.tokenTypeId,
              tokenTypeName: this.state.tokenTypeName,
            };
            payload = editTokenTypePayload;
          }

          try {
            if(this.selectedTokenType == null){
              let editTokenType = await ApiService.postData(
                "TokenType?name=" + this.state.tokenTypeName,
                payload,
                {}
              );
              if (editTokenType.status === 200) {
                this.$emit("editedTokenTypeSuccessfully");
              }

            }else{
              let putPayload = {
                tokenTypeId: payload.tokenTypeId,
                name: payload.tokenTypeName
              }
              let editTokenType = await ApiService.put(
                "TokenType",
                putPayload,
                {}
              );
              if (editTokenType.status === 200) {
                this.$emit("editedTokenTypeSuccessfully");
              }
            }
          } catch (err) {
            console.warn(err);
          }
        }
      }
    },
    discard() {
      this.$emit("closeEditTokenTypeForm");
    },
  },
  updated() {
    if (this.selectedTokenType) {
      this.allTokenTypes = this.tokenTypes.filter((value) => {
        let val = value as any;
        let selected = this.selectedTokenType as any;
        return val.tokenTypeName != selected.tokenTypeName;
      });
    }
    let tokenType_to_edit = this.selectedTokenType as Models.TokenType;
    if (
      this.selectedTokenType &&
      tokenType_to_edit.tokenTypeId != this.tokenTypeId
    ) {
      this.allTokenTypes = this.tokenTypes.filter((value) => {
        let val = value as any;
        let selected = this.selectedTokenType as any;
        return val.tokenTypeName != selected.tokenTypeName;
      });
      this.tokenTypeId = tokenType_to_edit.tokenTypeId;
      this.state.tokenTypeName = tokenType_to_edit.tokenTypeName;
    }
  },

  setup(props, context) {
    const store = useStore();
    const initialUser = store.getters.currentUser;
    let state: FormState = reactive({
      tokenTypeId: 0,
      tokenTypeName: "",
    });
    let tokenTypeId = 0;
    let allTokenTypes = props.tokenTypes;
    if (props.selectedTokenType) {
      allTokenTypes = props.tokenTypes.filter((value) => {
        let val = value as any;
        let selected = props.selectedTokenType as any;
        return val.tokenTypeName != selected.tokenTypeName;
      });
      let tokenType_to_edit = props.selectedTokenType as Models.TokenType;
      let stateEdit: FormState = reactive({
        tokenTypeId: tokenType_to_edit.tokenTypeId,
        tokenTypeName: tokenType_to_edit.tokenTypeName,
      });
      state = stateEdit;
      tokenTypeId = tokenType_to_edit.tokenTypeId;
    }

    const checkTokenTypeName = (value) => {
      let flag = true;
      allTokenTypes.forEach((val: any) => {
        if (val.tokenTypeName.toLowerCase() == value.toLowerCase()) {
          flag = false;
          return flag;
        }
      });
      return flag;
    };

    const mustBeUniqueName = (value) => checkTokenTypeName(value);

    const rules = computed(() => {
      return {
        tokenTypeName: {
          required: helpers.withMessage("Name is required.", required),
          mustBeUniqueName: helpers.withMessage(
            "Prompt Type name must be unique.",
            mustBeUniqueName
          ),
        },
      };
    });
    const v$ = useValidate(rules, state);

    const closeForm = () => {
      context.emit("closeEditTokenTypeForm");
    };

    return {
      mustBeUniqueName,
      state,
      v$,
      closeForm,
      tokenTypeId,
      allTokenTypes,
      checkTokenTypeName,
      initialUser,
    };
  },
});
