
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import ListingTokens from "../Tokens/ListingTokens.vue";
import AddGroup from "@/components/settings/Templates/Groups/AddGroup.vue";
import * as bootstrap from "bootstrap";

export default defineComponent({
  components: {
    ListingTokens,
    AddGroup,
  },
  data() {
    return {
      templateId: 0 as number,
      editTemplateObject: null as any,
      addGroupModal: bootstrap.Modal,
      editGroupModal: bootstrap.Modal,
      promptsList: [] as any[],
      templateList: [] as any[],
      groupToEdit: null as any,
      templateInitialName: "",
      isErrorUnique: false,
      isErrorEmpty: false,
      showGroupIsRequired:false,
      isTextErrorEmpty: false,
      // group: "",
      colors: [
        "#009ef7",
        "#f1416c",
        "#50cd89",
        "#ff9100",
        "#6253ff",
        "#f700ce",
        "#00bedc",
        "#dbdbdb",
        "#51f700",
        "#1a6792",
        "#7d1b33",
        "#000000",
        "#fff",
      ],
      groups: [] as any[],      
    };
  },
  methods: {
    setupAddGroupModal() {
      let modalElement = document.getElementById("addGroupModal");
      this.addGroupModal = new bootstrap.Modal(modalElement);
    },
    addGroup() {
      this.addGroupModal.show();
    },
    closeGroup() {
      this.addGroupModal.hide();
    },
    setupEditGroupModal() {
      let modalElement = document.getElementById("editGroupModal");
      this.editGroupModal = new bootstrap.Modal(modalElement);
    },
    editGroup() {      
      this.groupToEdit = this.groups.find(
        (x) => x.templateGroupId === this.editTemplateObject.templateGroupId
      );
      console.log(this.groupToEdit);
      this.editGroupModal.show();
    },
    closeEditGroup() {
      this.editGroupModal.hide();
    },
    selectColor(color) {
      this.editTemplateObject.background = color;      
    },
    async getAllTemplates() {
      await ApiService.get("Template").then((res) => {
        if (res) {
          let data = res.data;
          this.templateList = data;
          if (this.templateId === 0){
            this.editTemplateObject = {
              templateId: 0,      
              name: "",
              text: "",
              background: "",
              templateGroupId: null,        
              order: null,
              hidden: false
            }
          } else {
            this.editTemplateObject = data.filter((x) => x.templateId == this.templateId)[0];
            this.templateInitialName = this.editTemplateObject.name;
            setCurrentPageTitle(this.editTemplateObject.name);
          }
        }
      });
    },
    async getAllTokens() {
      await ApiService.get("Token").then((res) => {
        if (res) {
          this.promptsList = res.data;
        }
      });
    },
    selectPrompt(name) {
      let curPos = (document.getElementById("text") as any).selectionStart;
      let text_to_insert = ` ~${name}~ `;
      let previousText = this.editTemplateObject.text;
      this.editTemplateObject.text =
        previousText.slice(0, curPos) +
        text_to_insert +
        previousText.slice(curPos);
      // this.editTemplateObject.text = this.editTemplateObject.text + " " + "#" + name + "#";
    },
    isTemplateNameInUse(value) {
      var found = false;      
      for(let template of this.templateList){
        if (template === this.editTemplateObject){
          continue;
        }

        if (template.name.toLowerCase() === value.toLowerCase()) {
          found = true;
          break;
        }
      }
      return found;
    },
    async saveTemplate() {
      let payload = {
        templateId: this.editTemplateObject.templateId,
        name: this.editTemplateObject.name,
        text: this.editTemplateObject.text,
        background: this.editTemplateObject.background,
        templateGroupId: this.editTemplateObject.templateGroupId,
      };

      if (payload.name == null || payload.name == "") {
        this.isErrorEmpty = true;
      } else {
        this.isErrorEmpty = false;
      }

      if (payload.text == null || payload.text == "") {
        this.isTextErrorEmpty = true;
      } else {
        this.isTextErrorEmpty = false;
      }

      this.showGroupIsRequired = (payload.templateGroupId === null || payload.templateGroupId === 0);

      if (!this.isErrorEmpty && !this.isTextErrorEmpty) {
        if (this.isTemplateNameInUse(payload.name) == true) {
          this.isErrorUnique = true;
        } else {
          this.isErrorUnique = false;
        }
      }

      if (!this.isErrorEmpty && !this.isTextErrorEmpty && !this.isErrorUnique && !this.showGroupIsRequired) {
        try {
          let res = null as any;
          if (payload.templateId === 0){
            res  = await ApiService.postData("Template", payload, {});
          } else {
            res = await ApiService.put("Template", payload, {});
          }                 

          if (res.status === 200) {
            this.close();
          }
        } catch (err) {
          console.warn(err);
        }
      }
    },
    close() {
      this.$router.push({ path: "/notes/templates" });
    },
    async updateGroups(groupUpdated) {
      console.log("updating groups", groupUpdated);
      this.groupToEdit = null;
      await this.getGroups();
      if (this.addGroupModal) {
        this.addGroupModal.hide();
        this.closeEditGroup();
      }

    },
    async getGroups() {
      let res = await ApiService.get("Template/Group");
      if (res.status === 200) {
        let allGroups = res.data;
        let distinctiveGroups = [] as any[];
        allGroups.forEach((grp) => {
          if (!distinctiveGroups.find(z => z.name.toLowerCase() === grp.name.toLowerCase())) {
            distinctiveGroups.push(grp);
          }
        });
        console.log(distinctiveGroups);
        this.groups = distinctiveGroups;
      }
    },
  },
  async mounted() {
    const route = useRoute();
    const id = route.params.id;
    if (id){
      this.templateId = Number(id);
    }
    //const store = useStore();
    //const initialUser = store.getters.currentUser;

    this.setupEditGroupModal();
    this.setupAddGroupModal();
    await this.getAllTemplates();
    
    await this.getAllTokens();
    await this.getGroups();
  },  
});
