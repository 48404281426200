import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8a4820c0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card mb-5 mb-xxl-8" }
const _hoisted_2 = { class: "card-body pt-9 pb-0" }
const _hoisted_3 = { class: "d-flex flex-wrap flex-sm-nowrap mb-3" }
const _hoisted_4 = { class: "me-7 mb-4" }
const _hoisted_5 = { class: "symbol symbol-100px symbol-lg-160px symbol-fixed position-relative" }
const _hoisted_6 = { class: "flex-grow-1" }
const _hoisted_7 = { class: "d-flex justify-content-between align-items-start flex-wrap mb-2" }
const _hoisted_8 = { class: "d-flex flex-column" }
const _hoisted_9 = { class: "d-flex align-items-center mb-2" }
const _hoisted_10 = {
  href: "#",
  class: "text-gray-800 text-hover-primary fs-2 fw-bolder me-1"
}
const _hoisted_11 = { href: "#" }
const _hoisted_12 = {
  key: 0,
  class: "badge-light-success badge",
  title: "You're an admin"
}
const _hoisted_13 = { class: "d-flex flex-wrap fw-bold fs-6 mb-4 pe-2" }
const _hoisted_14 = {
  href: "#",
  class: "d-flex align-items-center text-gray-400 text-hover-primary mb-2"
}
const _hoisted_15 = { class: "svg-icon svg-icon-4 me-1" }
const _hoisted_16 = { class: "card p-5 animate" }
const _hoisted_17 = { class: "row d-flex justify-content-center" }
const _hoisted_18 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_avatar = _resolveComponent("avatar")!
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_EditProfile = _resolveComponent("EditProfile")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_avatar, { avatarSize: 5 })
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("a", _hoisted_10, _toDisplayString(_ctx.initialUser.firstName) + " " + _toDisplayString(_ctx.initialUser.lastName), 1),
                  _createElementVNode("a", _hoisted_11, [
                    (_ctx.initialUser.currentClinic.isAdmin)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_12, " Admin "))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("a", _hoisted_14, [
                    _createElementVNode("span", _hoisted_15, [
                      _createVNode(_component_inline_svg, { src: "/media/icons/duotone/Communication/Mail.svg" })
                    ]),
                    _createTextVNode(" " + _toDisplayString(_ctx.initialUser.email), 1)
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_16, [
      _createElementVNode("div", _hoisted_17, [
        (_ctx.userInfo)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "circle btn-bg-light",
              style: _normalizeStyle(`background-color:${_ctx.pickedColor == '' ? _ctx.userInfo.avatarColor : _ctx.pickedColor}; height:${4}rem; width:${4}rem;`),
              title: _ctx.userInfo.fullName
            }, [
              _createElementVNode("span", {
                class: "initials",
                style: _normalizeStyle(`font-size:${3/2}rem; top:${1.25}rem`)
              }, _toDisplayString(_ctx.userInfo.initials), 5)
            ], 12, _hoisted_18))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_EditProfile, {
        initialUser: _ctx.initialUser,
        colors: _ctx.colors,
        onCloseEdit: _ctx.goToMainPage,
        onChangeColor: _ctx.changeAvatarColor
      }, null, 8, ["initialUser", "colors", "onCloseEdit", "onChangeColor"])
    ])
  ], 64))
}