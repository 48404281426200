<template>
<div class="card" style="width: 100%; height: 100%;">
<!--begin::Header-->
<div class="card-header border-0 pt-5">
    <h3 class="card-title align-items-start flex-column">
    <span class="card-label fw-bolder fs-3 mb-1" id="tokens_title">Prompt</span>
    </h3>
    <h3 class="card-title align-items-end flex-column">
    <div
        class="card-toolbar card-label fw-bolder fs-3 mb-1"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        data-bs-trigger="hover"
        title="Click to add a prompt"
    >
        <a @click="addToken" class="btn btn-sm btn-light-primary button-font-shadow shadow" style="margin-right: -1rem" id="new_token_btn">
        <i class="fas fa-box-open"></i>
        New Prompt
        </a>
    </div>
    </h3>
</div>
<!--end::Header-->

<!--begin::Body-->
<div class="card-body py-3 row d-flex justify-content-center">
    <!--begin::Table container-->
    <ag-grid-vue
    :defaultColDef="defaultColDef"
    :columnDefs="columnDefs"
    :rowData="rowData"
    @first-data-rendered="gridSizeChanged"
    @grid-ready="onGridReady"
    @gridSizeChanged="gridSizeChanged"
    style="width: 100%; height: 99%; "
    class="ag-theme-balham"
    id="tokenGrid"
    >
    </ag-grid-vue>
    <!--end::Table container-->
</div>
<!--begin::Body-->
</div>

<!-- start modal -->
<div class="modal fade" tabindex="-1" id="editToken_modal" data-bs-backdrop="static" data-bs-keyboard="false">
<div class="modal-dialog modal-lg">
    <div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">Edit Prompt</h5>

        <!--begin::Close-->
        <div
        class="btn btn-icon btn-sm btn-active-light-primary ms-2"
        data-bs-dismiss="modal"
        aria-label="Close"
        >
        <span class="svg-icon svg-icon-2x"></span>
        <inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
        </div>
        <!--end::Close-->
    </div>
    <EditToken
        v-if="editTokenModalObject && tokens"
        v-model:selectedToken="editTokenModalObject"
        :tokens="tokens"
        :tokensTypes="tokenTypes"
        @closeEditTokenForm="closeEditTokenForm"
        @editedTokenSuccessfully="editedTokenSuccessfully"
    />
    </div>
</div>
</div>
<!-- start modal -->
<div class="modal fade" tabindex="-1" id="addToken_modal" data-bs-backdrop="static" data-bs-keyboard="false">
<div class="modal-dialog">
    <div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">Add Prompt</h5>

        <!--begin::Close-->
        <div
        class="btn btn-icon btn-sm btn-active-light-primary ms-2"
        data-bs-dismiss="modal"
        aria-label="Close"
        >
        <span class="svg-icon svg-icon-2x"></span>
        <inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
        </div>
        <!--end::Close-->
    </div>
    <EditToken
        v-if="addTokenVisible && tokens"
        :selectedToken="null"
        :tokens="tokens"
        :tokensTypes="tokenTypes"
        @closeEditTokenForm="closeEditTokenForm"
        @editedTokenSuccessfully="editedTokenSuccessfully"
    />
    </div>
</div>
</div>
<!-- end modal -->

<!-- start delete token confirmation -->
<div class="modal fade" tabindex="-1" id="deleteToken_modal" data-bs-backdrop="static" data-bs-keyboard="false">
<div class="modal-dialog">
    <div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title" v-if="tokenToDelete">Delete Prompt {{ tokenToDelete.name }}</h5>

        <!--begin::Close-->
        <div
        class="btn btn-icon btn-sm btn-active-light-primary ms-2"
        data-bs-dismiss="modal"
        aria-label="Close"
        >
        <span class="svg-icon svg-icon-2x"></span>
        <inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
        </div>
        <!--end::Close-->
    </div>
    <div class="modal-body">
        <p v-if="tokenToDelete">
        Are you sure you want to delete prompt  {{ tokenToDelete.name }}?
        </p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" data-bs-dismiss="modal">
        Cancel
        </button>
        <button
        v-if="tokenToDelete"
        type="button"
        class="btn btn-danger"
        @click="deleteToken(tokenToDelete.tokenId)"
        >
        Delete
        </button>
    </div>
    </div>
</div>
</div>
<!-- end delete token type confirmation -->
<!--end::Tables Widget 9-->
</template>

<script>
import * as bootstrap from "bootstrap";
import EditToken from "./EditToken.vue";
import { AgGridVue } from "ag-grid-vue3";
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import DeleteBtnCellRenderer from "../UserManagmentBtnCellRenderer.vue";
import BtnCellRederer from "@/components/settings/BtnCellRederer.vue";
import CheckBoxCellRenderer from "@/components/settings/CheckBoxCellRenderer.vue";
export default defineComponent({
name: "Tokens",
components: {
AgGridVue,
BtnCellRederer,
DeleteBtnCellRenderer,
EditToken,
CheckBoxCellRenderer,
},
props: {},
data() {
    return {
        addModal: null,
        editModal: null,
        deleteModal: null,
        tokenToDelete: null,

        tokens: null,
        defaultColDef: null,
        columnDefs: null,
        rowData: null,
        api: null,
        columnApi: null,
        addTokenVisible: false,
        editTokenVisible: false,
        editTokenModalObject: null,

        tokenTypes: null
    };
},

mounted() {
setCurrentPageTitle("Tokens");
    // addTokenType_modal
    let modalElement = document.getElementById("addToken_modal");
    this.addModal = new bootstrap.Modal(modalElement);
    if (modalElement) {
        var self = this;
        modalElement.addEventListener("hidden.bs.modal", function(event) {
            self.addTokenVisible = false;
        });
    }

    modalElement = document.getElementById("editToken_modal");
    this.editModal = new bootstrap.Modal(modalElement);
    if (modalElement) {
        var self = this;
        modalElement.addEventListener("hidden.bs.modal", function(event) {
            self.editTokenModalObject = null;
            self.editTokenVisible = false;
        });
    }
},

async beforeMount() {

this.refreshTable();
this.getTokenTypes();
},

methods: {
    async getTokenTypes(){
        let res = await ApiService.get("TokenType");
        if(res){
            this.tokenTypes = res.data;
        }
    },
    confirmTokenDeletion(data) {
        this.tokenToDelete = data;
        let modalElement = document.getElementById("deleteToken_modal");
        if (modalElement) {
        modalElement.addEventListener("hidden.bs.modal", function(event) {});
        }
        this.deleteModal = new bootstrap.Modal(modalElement);
        this.deleteModal.show();
    },
    onGridReady(params) {
        this.api = params.api;
        this.columnApi = params.columnApi;
    },
    gridSizeChanged() {
        this.api.sizeColumnsToFit();
    },
    // triggerGridSize
    async refreshTable() {
        this.defaultColDef = {
        resizable: true,
        sortable: true,
        filter: true,
        floatingFilter: true,
        suppressMenu: true,
        filterParams: {
            buttons: ["apply", "reset"],
            closeOnApply: true,
            suppressAndOrCondition: true,
        },
        };

        const tokensData = await ApiService.get("Token");
        let finaltokensData = tokensData.data;
        this.tokens = finaltokensData;
        for (let index = 0; index < finaltokensData.length; index++) {
        const token = finaltokensData[index];
        finaltokensData[index].edit = token;
        }
        let self = this;
        this.columnDefs = [
        {
            field: "tokenId",
            headerName: "Token Id",
            filter: true,
            resizable: true,
            hide: true
        },
        {
            field: "name",
            headerName: "Token Name",
            filter: true,
            resizable: true,
        },
        {
            field: "tokenTypeId",
            headerName: "Token Type Id",
            filter: true,
            resizable: true,
            hide: true
        },
        {
            field: "tokenTypeName",
            headerName: "Token Type Name",
            filter: true,
            resizable: true,
        },
        {
            field: "edit",
            headerName: "Actions",
            sortable: false,
            filter: false,
            cellRendererFramework: BtnCellRederer,
            resizable: true,
            maxWidth: "100",
            cellRendererParams: {
            clicked: function(selectedData) {
                self.editTokenModalObject = null;
                self.changeSelectedToken(selectedData);
                self.editTokenVisible = true;
            },
            deleteClicked: function(selectedData) {
                self.confirmTokenDeletion(selectedData);
            },
            },
        },
        ];
        this.rowData = finaltokensData;
    },
    async deleteToken(id) {
        let res = await ApiService.delete(`/Token/${id}`);
        if (res.status === 200) {
        this.refreshTable();
        this.deleteModal.hide();
        } else {
        alert("Something wen wrong while deleting prompt.");
        }
    },
    editedTokenSuccessfully() {
        this.closeEditTokenForm();
        this.refreshTable();
    },
    addToken() {
        this.addModal.show();
        this.addTokenVisible = true;
    },
    changeSelectedToken(token) {
        this.editModal.show();
        this.editTokenModalObject = token;
    },
    closeEditTokenForm() {
        this.editModal.hide();
        this.addModal.hide();
    },
},

});
</script>

<style lang="scss">
@import "../../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../../node_modules/ag-grid-community/dist/styles/ag-theme-balham.css";
</style>
