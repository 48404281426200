
import { defineComponent, computed } from "vue";
import HtmlClass from "@/core/services/LayoutService";
import { useStore } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";

import { UserContext } from "@/auth/UserContext";
import { string } from "yup/lib/locale";

export default defineComponent({
  name: "KeyPadView",
  data() {
    return {
      pin: "" as string,
      incorrectPin: false,
      clinicInfo: null,
    };
  },
  components: {},
  computed: {
    pinmask() {
      let mask = "";
      for (const c of this.pin) {
        mask = mask + "*";
      }
      return mask;
    },
  },
  watch: {
    async pin(newPin) {
      if (newPin.length === 4) {
        this.incorrectPin = false;
        try {
          const login = await ApiService.postData(
            "user/login",
            { pin: this.pin },
            {}
          );
          console.log("login");
          console.log(login);
          const clinicUserToken = login.data;
          localStorage.setItem("ClinicUserToken", clinicUserToken);
          ApiService.setClinicUserTokenHeader(clinicUserToken);
          const context = new UserContext(this.$route, this.$store);
          await context.buildCurrentUser();
          this.$router.push("dashboard");
        } catch (e: any) {
          if (e.response) {
            this.pin = "";
            if (e.response.status === 460) {
              //means incorrect pin
              this.incorrectPin = true;
              this.pin = "";
            }
          }
          console.log(e.response);
        }
      }
    },
  },
  methods: {
    logOut() {
      this.$router.push("/logout");
    },
    clear() {
      this.pin = "";
    },
    backSpace() {
      const currentInput = this.pin;
      if (currentInput !== "")
        this.pin = currentInput.substring(0, currentInput.length - 1);
    },
    keyPress(number) {
      this.incorrectPin = false;
      this.pin = this.pin + number;
    },
    async go() {
      this.incorrectPin = false;
      try {
        const login = await ApiService.postData(
          "user/login",
          {
            pin: this.pin,
          },
          {}
        );
        console.log("login");
        console.log(login);
        localStorage.setItem("ClinicUserToken", login.data);
        location.reload();
      } catch (e: any) {
        if (e.response) {
          if (e.response.status === 460) {
            //means incorrect pin
            this.incorrectPin = true;
            this.pin = "";
          }
        } 
        console.log(e.response);
      }
    },
  },
  async beforeMount() {
    if (!this.$auth.isAuthenticated) {
      alert("not authenticated");
    }

    const clinicInfo = await (await ApiService.get("clinic")).data;
    if (!clinicInfo.enablePin) {
      alert("this clinic does not allow pin authentication");
    }
    this.clinicInfo = clinicInfo;
    const clinicUserToken = localStorage.getItem("ClinicUserToken");
    if (clinicUserToken) {
      ApiService.setClinicUserTokenHeader(clinicUserToken);
    }

    console.log("cinic info", this.clinicInfo);
  },

  setup() {
    const store = useStore();

    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });

    const breadcrumbs = computed(() => {
      return store.getters.pageBreadcrumbPath;
    });

    // show page loading
    //store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    //HtmlClass.init();

    return {
      pageTitle: pageTitle,
    };
  },
});
