import { VueAuth, Auth0Options, RedirectCallback } from './VueAuth'

type Auth0PluginOptions = {
  onRedirectCallback: RedirectCallback,
  domain: string,
  clientId: string,
  audience?: string,
  [key: string]: string | RedirectCallback | undefined
}

/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK = (appState) =>
  window.history.replaceState({}, document.title, window.location.pathname)

let instance: VueAuth = new VueAuth()
let instanceInitialized: boolean = false;


/** Returns the current instance of the SDK */
export const getInstance = () => instance

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useAuth0 = async ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
  ...options
}) => {
  
  console.log("calling useAuth0")
  if (instanceInitialized) {
    return instance
  }
  await instance.init(onRedirectCallback, redirectUri, options as Auth0Options)
  instanceInitialized = true;
  return instance
}

