<template>
  <div class="row" style="text-align: center;">
    <div class="form-check " v-if="!params.value"></div>
    <div class="form-check " v-else>
      <p>Yes</p>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
export default defineComponent({
  name: "CheckBoxCellRenderer",
  components: {},
  props: {},
  data() {
    return {};
  },
  methods: {
    btnClickedHandler() {
      this.params.clicked(this.params.value);
    },
  },
});
</script>

<style scoped>
.red {
  color: red;
}
.green {
  color: green;
}
div {
  padding-top: 2px;
}
</style>
