
import { defineComponent, reactive, computed } from "vue";
import useValidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import ApiService from "@/core/services/ApiService";
import { Models } from "@/Models/Models";

interface FormState {
  firstName: string;
  lastName: string;
}

export default defineComponent({
  props: {
    initialUser: {
      type: Object,
      required: true,
    },
    colors: {
      required: true,
    },
  },
  data() {
    return {
      pickedColor: "",
    };
  },
  created() {
    if (this.initialUser.preferences) {
      let color = JSON.parse(this.initialUser.preferences).avatarColor;
      this.pickedColor = color;
      this.$emit("changeColor", color);
    }
  },
  methods: {
    async submit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        try {
          // const initialUser = this.initialUser as any;
          const user = await ApiService.get(`User/current`);
          let currentUser = user.data as Models.CurrentUser;
          // const clinicAccess: Models.ClinicAccess[] = [];
          // currentUser.clinics.forEach((clinic) => {
          //   const clinicId = clinic.clinicId;
          //   let access = false;
          //   if (
          //     clinicId === initialUser.currentClinic.clinicId &&
          //     initialUser.currentClinic.isAdmin
          //   ) {
          //     access = true;
          //   }
          //   clinicAccess.push({
          //     clinicId: clinicId,
          //     isClinicAdmin: access,
          //   });
          // });
          if(!currentUser.preferences || currentUser.preferences == "" ){
            currentUser.preferences = JSON.stringify({});
          }
          let newPrefs = JSON.parse(currentUser.preferences);
          newPrefs.avatarColor = this.pickedColor;
          let payload: any = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            preferences: JSON.stringify(newPrefs),
          };
          if (!payload) {
            return;
          } else {
            const response = await ApiService.postData("/User/profile",payload,{});
            if (response.status === 200) {
              window.location.reload();
            } else {
              console.log(response);
            }
          }
        } catch (ex) {
          console.log(ex);
        }
      }
    },
    selectColor(color) {
      this.pickedColor = color;
      this.$emit("changeColor", color);
      console.log(color);
    },
  },
  setup(props, context) {
    let state: FormState = reactive({
      firstName: props.initialUser.firstName,
      lastName: props.initialUser.lastName,
    });

    const rules = computed(() => {
      return {
        firstName: {
          required: helpers.withMessage("First name is required.", required),
        },
        lastName: {
          required: helpers.withMessage("Last name is required.", required),
        },
      };
    });
    const v$ = useValidate(rules, state);

    const closeEdit = () => {
      context.emit("closeEdit");
    };
    return {
      closeEdit,
      state,
      v$,
    };
  },
});
