<template>
  <div>
    <stripe-pricing-table
      pricing-table-id="prctbl_1Ljn8xLfKAozEUQKC3ENbEws"
      publishable-key="pk_live_51LiNmWLfKAozEUQK1nmYodX9QntVmficVYwo1msvS2iCiK1ihHJN2QSrH260chjD6Qp8jaSPpOlM6efM7osCAD4400KERXuboL"
      success-url="https://www.google.com"
      cancel-url="https://www.google.com"
    >
    </stripe-pricing-table>
  </div>
</template>

<script>
import { StripeCheckout } from "@vue-stripe/vue-stripe";
export default {
  components: {
    StripeCheckout,
  },
  data() {
    return {
      // publishableKey = 'pk_test_51LikVkDbQltqZ8gICgoehdzjsg7hv3a1Pqpz87Gyq78gkd4ljnDg4F989jVCQCB28FlNyCZmBFl5mwBUgJAmdzMv00h4Rxyys7',
      // loading: false,
      // lineItems: [
      //   {
      //     price: 'price_1LikmSDbQltqZ8gIPjzKW00y', // The id of the recurring price you created in your Stripe dashboard
      //     quantity: 1,
      //   },
      // ],
      // successURL: 'http://localhost:8080/setupaccount',
      // cancelURL: 'http://localhost:8080/setupaccount',
    };
  },
  mounted() {
    let externalScript = document.createElement("script");
    externalScript.setAttribute(
      "src",
      "https://js.stripe.com/v3/pricing-table.js"
    );
    document.head.appendChild(externalScript);
  },
  methods: {
    // submit () {
    //   this.$refs.checkoutRef.redirectToCheckout();
    // },
  },
};
</script>
