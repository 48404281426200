<template>
<div class="card" style="width: 100%; height: 100%;">
<!--begin::Header-->
<div class="card-header border-0 pt-5">
    <h3 class="card-title align-items-start flex-column">
    <span class="card-label fw-bolder fs-3 mb-1" id="token_types_title">Prompt Types</span>
    </h3>
    <h3 class="card-title align-items-end flex-column">
    <div
        class="card-toolbar card-label fw-bolder fs-3 mb-1"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        data-bs-trigger="hover"
        title="Click to add a token type"
    >
        <a @click="addTokenType" class="btn btn-sm btn-light-primary button-font-shadow shadow" style="margin-right: -1rem" id="new_token_type_btn">
        <i class="fas fa-box-open"></i>
        New Prompt Type
        </a>
    </div>
    </h3>
</div>
<!--end::Header-->

<!--begin::Body-->
<div class="card-body py-3 row d-flex justify-content-center">
    <!--begin::Table container-->
    <ag-grid-vue
    :defaultColDef="defaultColDef"
    :columnDefs="columnDefs"
    :rowData="rowData"
    @first-data-rendered="gridSizeChanged"
    @grid-ready="onGridReady"
    @gridSizeChanged="gridSizeChanged"
    style="width: 100%; height: 99%; "
    class="ag-theme-balham"
    id="tokentypesGrid"
    >
    </ag-grid-vue>
    <!--end::Table container-->
</div>
<!--begin::Body-->
</div>

<!-- start modal -->
<div class="modal fade" tabindex="-1" id="editTokenType_modal" data-bs-backdrop="static" data-bs-keyboard="false">
<div class="modal-dialog">
    <div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">Edit Prompt Type</h5>

        <!--begin::Close-->
        <div
        class="btn btn-icon btn-sm btn-active-light-primary ms-2"
        data-bs-dismiss="modal"
        aria-label="Close"
        >
        <span class="svg-icon svg-icon-2x"></span>
        <inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
        </div>
        <!--end::Close-->
    </div>
    <EditTokenType
        v-if="editTokenTypeModalObject && tokenTypes"
        v-model:selectedTokenType="editTokenTypeModalObject"
        :tokenTypes="tokenTypes"
        @closeEditTokenTypeForm="closeEditTokenTypeForm"
        @editedTokenTypeSuccessfully="editedTokenTypeSuccessfully"
    />
    </div>
</div>
</div>
<!-- start modal -->
<div class="modal fade" tabindex="-1" id="addTokenType_modal" data-bs-backdrop="static" data-bs-keyboard="false">
<div class="modal-dialog">
    <div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">Add Prompt Type</h5>

        <!--begin::Close-->
        <div
        class="btn btn-icon btn-sm btn-active-light-primary ms-2"
        data-bs-dismiss="modal"
        aria-label="Close"
        >
        <span class="svg-icon svg-icon-2x"></span>
        <inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
        </div>
        <!--end::Close-->
    </div>
    <EditTokenType
        v-if="addTokenTypeVisible && tokenTypes"
        :selectedTokenType="null"
        :tokenTypes="tokenTypes"
        @closeEditTokenTypeForm="closeEditTokenTypeForm"
        @editedTokenTypeSuccessfully="editedTokenTypeSuccessfully"
    />
    </div>
</div>
</div>
<!-- end modal -->

<!-- start delete token Type confirmation -->
<div class="modal fade" tabindex="-1" id="deleteTokenType_modal" data-bs-backdrop="static" data-bs-keyboard="false">
<div class="modal-dialog">
    <div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title" v-if="tokenTypeToDelete">Delete Prompt Type {{ tokenTypeToDelete.tokenTypeName }}</h5>

        <!--begin::Close-->
        <div
        class="btn btn-icon btn-sm btn-active-light-primary ms-2"
        data-bs-dismiss="modal"
        aria-label="Close"
        >
        <span class="svg-icon svg-icon-2x"></span>
        <inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
        </div>
        <!--end::Close-->
    </div>
    <div class="modal-body">
        <p v-if="tokenTypeToDelete">
        Are you sure you want to delete prompt type {{ tokenTypeToDelete.tokenTypeName }}?
        </p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" data-bs-dismiss="modal">
        Cancel
        </button>
        <button
        v-if="tokenTypeToDelete"
        type="button"
        class="btn btn-danger"
        @click="deleteTokenType(tokenTypeToDelete.tokenTypeId)"
        >
        Delete
        </button>
    </div>
    </div>
</div>
</div>
<!-- end delete token type confirmation -->
<!--end::Tables Widget 9-->
</template>

<script>
import * as bootstrap from "bootstrap";
import EditTokenType from "./EditTokenType.vue";
import { AgGridVue } from "ag-grid-vue3";
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import DeleteBtnCellRenderer from "../UserManagmentBtnCellRenderer.vue";
import BtnCellRederer from "@/components/settings/BtnCellRederer.vue";
import CheckBoxCellRenderer from "@/components/settings/CheckBoxCellRenderer.vue";
export default defineComponent({
name: "TokenTypes",
components: {
AgGridVue,
BtnCellRederer,
DeleteBtnCellRenderer,
EditTokenType,
CheckBoxCellRenderer,
},
props: {},
data() {
    return {
        addModal: null,
        editModal: null,
        deleteModal: null,
        tokenTypeToDelete: null,

        tokenTypes: null,
        defaultColDef: null,
        columnDefs: null,
        rowData: null,
        api: null,
        columnApi: null,
        addTokenTypeVisible: false,
        editTokenTypeVisible: false,
        editTokenTypeModalObject: null,
    };
},

mounted() {
setCurrentPageTitle("Prompt Types");
    // addTokenType_modal
    let modalElement = document.getElementById("addTokenType_modal");
    this.addModal = new bootstrap.Modal(modalElement);
    if (modalElement) {
        var self = this;
        modalElement.addEventListener("hidden.bs.modal", function(event) {
            self.addTokenTypeVisible = false;
        });
    }

    modalElement = document.getElementById("editTokenType_modal");
    this.editModal = new bootstrap.Modal(modalElement);
    if (modalElement) {
        var self = this;
        modalElement.addEventListener("hidden.bs.modal", function(event) {
            self.editTokenTypeModalObject = null;
            self.editTokenTypeVisible = false;
        });
    }
},

async beforeMount() {
this.refreshTable();
},

methods: {
    confirmTokenTypeDeletion(data) {
        this.tokenTypeToDelete = data;
        let modalElement = document.getElementById("deleteTokenType_modal");
        if (modalElement) {
        modalElement.addEventListener("hidden.bs.modal", function(event) {});
        }
        this.deleteModal = new bootstrap.Modal(modalElement);
        this.deleteModal.show();
    },
    onGridReady(params) {
        this.api = params.api;
        this.columnApi = params.columnApi;
    },
    gridSizeChanged() {
        this.api.sizeColumnsToFit();
    },
    // triggerGridSize
    async refreshTable() {
        this.defaultColDef = {
        resizable: true,
        sortable: true,
        filter: true,
        floatingFilter: true,
        suppressMenu: true,
        filterParams: {
            buttons: ["apply", "reset"],
            closeOnApply: true,
            suppressAndOrCondition: true,
        },
        };

        const tokenTypesData = await ApiService.get("TokenType");
        let finaltokenTypesData = tokenTypesData.data;
        this.tokenTypes = finaltokenTypesData;

        for (let index = 0; index < finaltokenTypesData.length; index++) {
        const tokentype = finaltokenTypesData[index];
        finaltokenTypesData[index].edit = tokentype;
        }
        let self = this;
        this.columnDefs = [
        {
            field: "tokenTypeId",
            headerName: "Token Type Id",
            filter: true,
            resizable: true,
        },
        {
            field: "tokenTypeName",
            headerName: "Token Type Name",
            filter: true,
            resizable: true,
        },

        {
            field: "edit",
            headerName: "Actions",
            sortable: false,
            filter: false,
            cellRendererFramework: BtnCellRederer,
            resizable: true,
            maxWidth: "100",
            cellRendererParams: {
            clicked: function(selectedData) {
                self.editTokenTypeModalObject = null;
                self.changeSelectedTokenType(selectedData);
                self.editTokenTypeVisible = true;
            },
            deleteClicked: function(selectedData) {
                self.confirmTokenTypeDeletion(selectedData);
            },
            },
        },
        ];
        this.rowData = finaltokenTypesData;
    },
    async deleteTokenType(id) {
        let res = await ApiService.delete(`/TokenType/${id}`);
        if (res.status === 200) {
        this.refreshTable();
        this.deleteModal.hide();
        } else {
        alert("Something wen wrong while deleting prompt type.");
        }
    },
    editedTokenTypeSuccessfully() {
        this.closeEditTokenTypeForm();
        this.refreshTable();
    },
    addTokenType() {
        this.addModal.show();
        this.addTokenTypeVisible = true;
    },
    changeSelectedTokenType(tokenType) {
        this.editModal.show();
        this.editTokenTypeModalObject = tokenType;
    },
    closeEditTokenTypeForm() {
        this.editModal.hide();
        this.addModal.hide();
    },
},

});
</script>

<style lang="scss">
@import "../../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../../node_modules/ag-grid-community/dist/styles/ag-theme-balham.css";
</style>
