
import { defineComponent, reactive, computed } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import Avatar from "@/components/Avatar.vue";
import ApiService from "@/core/services/ApiService";
import Subscriptions from "@/components/Stripe/Subscriptions.vue";
import { helpers, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default defineComponent({
  name: "SetupAccount",
  props: {},
  components: {
    Avatar,
    Subscriptions,
  },
  data() {
    return {
      currentEmail: "",
      colors: [
        "#FFB900",
        "#D83B01",
        "#B50E0E",
        "#E81123",
        "#B4009E",
        "#5C2D91",
        "#0078D7",
        "#00B4FF",
        "#008272",
        "#107C10",
      ],

      pickedColor: "#0078D7",
    };
  },
  mounted() {
    setCurrentPageTitle("Setup Your Account");
    this.currentEmail = this.parseJwt((this.$store.state as any).token)[
      "https://verixa/email"
    ];
  },
  methods: {
    parseJwt(token) {
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      return JSON.parse(jsonPayload);
    },
    async submitAccount() {
      let body = {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        avatarColor: this.state.avatarColor,
        address: this.state.address,
        city: this.state.city,
        state: this.state.state,
        country: this.state.country,
        postalCode: this.state.postalCode,
      };

      this.v$.$validate();

      if (!this.v$.$error) {
        try {
          let res = await ApiService.postData("/Account", body, {});
          if (res.status == 200) {
            // I THINK WE NEED TO ROUTE TO ANOTHER PAGE
            this.$router.push("/chooseplan");
          }
        } catch (ex) {
          console.log(ex);
        }
      }
    },
    selectColor(color) {
      this.state.avatarColor = color;
      this.pickedColor = color;
    },
  },
  setup() {
    const state = reactive({
      firstName: "",
      lastName: "",
      avatarColor: "#0078D7",
      address: "",
      city: "",
      state: "",
      country: "",
      postalCode: "",
    });

    const rules = computed(() => {
      return {
        firstName: {
          required: helpers.withMessage("First name is required.", required),
        },
        lastName: {
          required: helpers.withMessage("Last name is required.", required),
        },
        address: {
          required: helpers.withMessage("Address is required.", required),
        },
        city: {
          required: helpers.withMessage("City is required.", required),
        },
        state: {
          required: helpers.withMessage(
            "Province/State is required.",
            required
          ),
        },
        country: {
          required: helpers.withMessage("Country is required.", required),
        },
        postalCode: {
          required: helpers.withMessage("Postal Code is required.", required),
        },
      };
    });

    const v$ = useVuelidate(rules, state);

    return { v$, state };
  },
});
