
import { defineComponent } from "vue";
import * as bootstrap from "bootstrap";
import ApiService from "@/core/services/ApiService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import Multiselect from "@vueform/multiselect";

export default defineComponent({
	name: "Template",
	props: {},
	components: {
		Multiselect,
	},

	data() {
		return {
			loading: false,
			surfacesModal: null,
			showFullScreen: false,
			selectorIndex: 0,
			copied_clipboard: false,
			noteModal: bootstrap.Modal,
			noteTemplateSelected: "",
			noteIsSelected: false,
			fieldList: new Array(),
			noteTemplate: "",
			initialNoteTemplate: "",
			multiselectValues: {},
			extra_values: {},
			rowData: new Array(),
			promptsList: new Array(),
			promptsTypeList: new Array(),
			promptSelectValues: new Array(),

			type5Object: [{
				selectedTeeth: new Array(),
				surfaces: new Array(),
			}],

			teeth: {
				first: [11, 12, 13, 14, 15, 16, 17, 18],
				second: [21, 22, 23, 24, 25, 26, 27, 28],
				third: [31, 32, 33, 34, 35, 36, 37, 38],
				fourth: [41, 42, 43, 44, 45, 46, 47, 48],
				fifth: [51, 52, 53, 54, 55, 'NSF'],
				sixth: [61, 62, 63, 64, 65, 'GEN'],
				seventh: [71, 72, 73, 74, 75],
				eighth: [81, 82, 83, 84, 85],
			},
		};
	},
	async mounted() {		
		this.loading = true;
		await this.getAllTemplates();
		await this.getAllTokens();
		await this.getAllTokenTypes();
		await this.getAllTokenValues();
		// let selectedName = this.$route.params.name || x;
		let selectedName = this.$route.params.name;
		if (selectedName != null) {
			this.noteTemplateSelected = selectedName.toString();
			setCurrentPageTitle(this.noteTemplateSelected);
			this.createNewNote();
			this.loading = false;
		}
		let modalElement = document.getElementById("surfaces_modal");
		this.surfacesModal = new bootstrap.Modal(modalElement);
	},
	methods: {
		multiselectClick(option, item, e){
			let noneOption = item.list.find(v => v.isNone);
			if (!noneOption){
				return;
			}			
			
			let allOptions =  Array.from(document.querySelectorAll(`[data-group="${item.name}"]`));			
			let noneOptionHtml = allOptions.find(v => (v as HTMLInputElement).value === noneOption.value) as HTMLInputElement;
			console.log("option clicked", noneOptionHtml);
			if (option.isNone){ //was the none option clicked? 
				console.log("option none clicked");
				if (noneOptionHtml.checked){ //if yes, then uncheck everything else
					console.log("unckecking");
					allOptions.filter(v => (v as HTMLInputElement).value !== noneOption.value)
						.forEach(v => {
							(v as HTMLInputElement).checked = false
							let inputVal = v as HTMLInputElement;
							let counterInputVal = document.getElementById(`counter${inputVal.id}`) as HTMLInputElement || "";
							if (counterInputVal){							
								counterInputVal.value = '';								
							}							
						});					
				}
			} else { //someting else was clicked, clear the none option
				//find the nulll option				
				noneOptionHtml.checked = false;
			}
		},
		multiselectAndCountClick(option, item, e){
			console.log("multiselectAndCountClick", option, item, e);
			console.log("fieldlist",this.fieldList);
			console.log("checked", e.target.checked);
			let inputVal = document.getElementById(`_${option.tokenPossibleValueId}`) as HTMLInputElement;
			let counterInputVal = document.getElementById(`counter_${option.tokenPossibleValueId}`) as HTMLInputElement || "";
			if (counterInputVal){
				if (!counterInputVal.value || counterInputVal.value === '0') {
					counterInputVal.value = '1';
				}

				if (!e.target.checked){
					counterInputVal.value = '';
				}
			}
			this.multiselectClick(option, item, e);
			this.instantPreview();
		},

		//returns text with prompts highlighted
		returnHighlightedNote() {
			let prompts = this.promptsList.map(x => x.name);
			for (let i = 0; i < prompts.length; i++) {
				prompts[i] = `~${prompts[i]}~`
			}
			let initialText = this.noteTemplate;
			prompts.forEach(prompt => {
				initialText = initialText.replaceAll(prompt, `<b>${prompt}</b>`)
			});
			if (this.$refs.textareafake) {
				console.log("filling div");
				(this.$refs.textareafake as HTMLDivElement).innerHTML = `${initialText.replaceAll("\n", "<br />\n")}`;
			}
		},

		//return surfaces for current tooth number
		checkSurfacesForTeethNumber(endingToothNumber) {
			let num = endingToothNumber.toString().split("")[1];
			if (num == 4 || num == 5 || num == 6 || num == 7 || num == 8) {
				return ["M", "O", "D", "B", "L"];
			} else if (num == 1 || num == 2 || num == 3) {
				return ["M", "I", "D", "B", "L"];
			}
		},

		//used to set text field type value
		inputClicked(event) {
			console.log(event.key);
			let enterClicked = event.key == "Enter";
			if (enterClicked) {
				if (this.selectorIndex < this.fieldList.length - 1) {
					this.selecterdecrementOrIncrement(1);
				} else if (this.selectorIndex == this.fieldList.length - 1) {
					this.preview();
				}
			}
		},

		// used for previous and next button knows when to revert step or live preview current and go to next one
		selecterdecrementOrIncrement(inc) {
			if (inc > 0) {
				this.livePreview(this.fieldList[this.selectorIndex].name);
			} else if (inc < 0) {
				this.revertStep();
			}
			if (this.selectorIndex < this.fieldList.length && this.selectorIndex >= 0) {
				this.selectorIndex += inc;
				this.selectorIndex = this.selectorIndex < 0 ? 0 : this.selectorIndex;
				this.selectorIndex = this.selectorIndex >= this.fieldList.length ? this.fieldList.length - 1 : this.selectorIndex;
			}
		},


		async getAllTemplates() {
            let res = await ApiService.get("Template");
			this.rowData = res.data;
		},


		async getAllTokens() {
			await ApiService.get("Token").then((res) => {
				if (res) {
					this.promptsList = res.data;
					console.log(this.promptsList);
				}
			});
		},


		async getAllTokenTypes() {
			await ApiService.get("TokenType").then((res) => {
				if (res) {
					this.promptsTypeList = res.data;
				}
			});
		},


		async getAllTokenValues() {
			await ApiService.get("TokenPossibleValue").then((res) => {
				if (res) {
					this.promptSelectValues = res.data;
				}
			});
		},

		//used to set extra text value on top
		setFieldExtraValue(e, name) {
			console.log(e.target.value, name);
			this.extra_values[`${name.replaceAll(" ", "_")}`] = e.target.value;
			this.instantPreview()
		},

		//gets tokens from note and create the fieldList
		createNewNote() {
			this.fieldList = [];
			this.noteIsSelected = true;
			this.noteTemplate = this.rowData.filter((x) => x.name == this.noteTemplateSelected)[0].text;
			this.initialNoteTemplate = this.noteTemplate;
			let regexp = new RegExp("~(.*?)~", "g");
			let selectedUsedPrompts: RegExpMatchArray | null = [];

			selectedUsedPrompts = Array.from(this.noteTemplate.matchAll(regexp), (m) => m[0]);
			console.log(selectedUsedPrompts);
			let usedPrompts = new Array();
			let newUsedPrompts = new Array();

			if (selectedUsedPrompts && selectedUsedPrompts[0]) {
				usedPrompts = selectedUsedPrompts[0].split("~");
				selectedUsedPrompts.forEach((element) => {
					if (element.includes("~")) {
						element = element.replaceAll("~", "");
						if (!newUsedPrompts.includes(element)) {
							newUsedPrompts.push(element);
						}
					}
				});

				newUsedPrompts.forEach(async (element) => {
					for (var i = 0; i < this.promptsList.length; i++) {
						if (this.promptsList[i].name.toLowerCase() == element.toLowerCase()) {
							let type = this.promptsTypeList.filter((x) => x.tokenTypeId == this.promptsList[i].tokenTypeId);
							let list = new Array();

							if (type[0].tokenTypeName == "Drop down" || type[0].tokenTypeName == "Multiselect" || type[0].tokenTypeName == "Multiselect and count" || type[0].tokenTypeName == "checklist") {
								// await this.getAllTokenValues(this.promptsList[i].tokenId);
								// list = this.promptSelectValues;
								list = this.promptSelectValues.filter((x) => x.tokenId == this.promptsList[i].tokenId);
							} else {
								list = [];
							}
							if (type[0].tokenTypeName == "Tooth surface select" || type[0].tokenTypeName == "Toothselect") {
								this.type5Object[element] = {
									selectedTeeth: [],
									surfaces: [],
								}
							}
							this.fieldList.push({ name: element, type: type[0].tokenTypeName, list: list });
						}
					}
				});
				console.log(this.type5Object)
			}
		},

		//same as live preview func but gets called onChange for any input concerning prompts
		instantPreview() {
			this.showFullScreen = false;
			this.returnHighlightedNote();
			this.noteTemplate = this.initialNoteTemplate;
			let currentIndex: number = 0;
			this.fieldList.forEach((field) => {
				if (currentIndex <= this.selectorIndex) {
					this.processField(field);
					currentIndex++;
				} else {
					return;
				}
			});
		},

		//goes back one step and completes all but current step
		revertStep() {
			this.showFullScreen = false;
			this.noteTemplate = this.initialNoteTemplate;
			let currentIndex: number = 0;
			this.fieldList.forEach((field) => {
				if (currentIndex < this.selectorIndex - 1) {
					this.processField(field);
					currentIndex++;
				} else {
					return;
				}
			});
			this.returnHighlightedNote();

		},

		// goes forward 1 step and completes previous step
		livePreview(id) {
			let field = this.fieldList.find((x) => x.name == id);
			this.processField(field);
		},

		// completes all steps in one go and copies to clipboard
		preview() {
			this.noteTemplate = this.initialNoteTemplate;
			console.log(this.fieldList);

			this.fieldList.forEach((field) => {
				this.processField(field);
			});
			let self = this;
			this.fieldList.forEach((f) => {
				this.noteTemplate = this.noteTemplate.replaceAll(`~${f.name}~`, "");
				this.noteTemplate = this.noteTemplate.replaceAll("<mark>", "")
				this.noteTemplate = this.noteTemplate.replaceAll("</mark>", "")

			});
			navigator.clipboard.writeText(this.noteTemplate).then(
				function () {
					console.log("Async: Copying to clipboard was successful!");
					self.copied_clipboard = true;
					self.showFullScreen = true;
					setTimeout(() => {
						self.copied_clipboard = false;
					}, 2000);
				},
				function (err) {
					console.error("Async: Could not copy text: ", err);
				}
			);
		},

		processField(field){
			let inputVal = document.getElementById(field.name) as HTMLInputElement;
			let extra_valuetext = this.extra_values[field.name.replaceAll(" ", "_")] || "";

			if (field.type == "Text") {
				if (inputVal.value && inputVal.value != "") {
					this.noteTemplate = this.noteTemplate.replaceAll("~" + field.name + "~", `<mark>${inputVal.value}${extra_valuetext ? " " + extra_valuetext : ""}</mark>`);
				}
			}
			let selectVal = document.getElementById(field.name) as HTMLSelectElement;

			if (field.type == "Drop down") {
				if (selectVal.value && selectVal.value != "") {
					this.noteTemplate = this.noteTemplate.replaceAll("~" + field.name + "~", `<mark>${selectVal.value}${extra_valuetext ? " " + extra_valuetext : ""}</mark>`);
				}
			} else if (field.type == "Multiselect") {
				let values = "";
				let count = 0;
				console.log(field);
				let sep = this.promptsList.find((x) => x.tokenId == field.list[0].tokenId).seperator;
				for (let i = 0; i < field.list.length; i++) {
					const element = field.list[i];
					let inputVal = document.getElementById(element.tokenPossibleValueId) as HTMLInputElement;

					if (inputVal && inputVal.checked && inputVal.value && inputVal.value != "") {
						count += 1;
						count == 1 ? (values = inputVal.value) : (values = values + (sep == null ? "" : sep || "") + (inputVal.value || ""));
					}
				}
				if (values != null && values !== "") {
					this.noteTemplate = this.noteTemplate.replaceAll("~" + field.name + "~", `<mark>${values}${extra_valuetext ? " " + extra_valuetext : ""}</mark>`);
				} else if (extra_valuetext != "") {
					this.noteTemplate = this.noteTemplate.replaceAll("~" + field.name + "~", `<mark>${extra_valuetext ? " " + extra_valuetext : ""}</mark>`);
				}
			} else if (field.type == "Multiselect and count") {
				let values = "";
				let count = 0;
				console.log(field);
				let sep = this.promptsList.find((x) => x.tokenId == field.list[0].tokenId).seperator;
				for (let i = 0; i < field.list.length; i++) {
					const element = field.list[i];
					let inputVal = document.getElementById(`_${element.tokenPossibleValueId}`) as HTMLInputElement;
					let counterInputVal = document.getElementById(`counter_${element.tokenPossibleValueId}`) as HTMLInputElement || "";
					if (counterInputVal){
						if (Number(counterInputVal.value) > 0) {
							inputVal.checked = true;
						} else {
							inputVal.checked = false;
						}
						if (inputVal && inputVal.checked && inputVal.value && inputVal.value != "") {
							count += 1;
							count == 1 ? (values = (counterInputVal.value ? ` ${counterInputVal.value}` : '') + " " + inputVal.value) : (values = values + (sep == null ? "" : sep || "") + (counterInputVal.value ? ` ${counterInputVal.value}` : '') + " " + (inputVal.value || ""));
						}
					} else {
						if (inputVal.checked){
							values = inputVal.value;
						}
						
					}
				}
				if (values != null && values !== "") {
					this.noteTemplate = this.noteTemplate.replaceAll("~" + field.name + "~", `<mark>${values}${extra_valuetext ? " " + extra_valuetext : ""}</mark>`);
				} else if (extra_valuetext != "") {
					this.noteTemplate = this.noteTemplate.replaceAll("~" + field.name + "~", `<mark>${extra_valuetext ? " " + extra_valuetext : ""}</mark>`);
				}
			} else if (field.type == "Toothselect") {
				let toothSelect = this.type5Object[field.name];
				if (toothSelect && toothSelect.selectedTeeth != null && toothSelect.selectedTeeth.length > 0) {
					let type5String = "";
					for (let z = 0; z < toothSelect.selectedTeeth.length; z++) {
						if (z == toothSelect.selectedTeeth.length - 1) {
							let t = toothSelect.selectedTeeth[z];
							let s = toothSelect.surfaces[z] ?? [];
							type5String += `${t}${s.join("")}`;
						} else {
							let t = toothSelect.selectedTeeth[z];
							let s = toothSelect.surfaces[z] ?? [];
							type5String += `${t}${s.join("")}, `;
						}
					}
					this.noteTemplate = this.noteTemplate.replaceAll("~" + field.name + "~", `<mark>${type5String.toString()}${extra_valuetext ? ' ' + extra_valuetext : ""}</mark>`);
				}
			} else if (field.type == "Tooth surface select") {
				if (this.type5Object[field.name] && this.type5Object[field.name].selectedTeeth != null && this.type5Object[field.name].selectedTeeth.length > 0) {
					let type5String = "";
					for (let z = 0; z < this.type5Object[field.name].selectedTeeth.length; z++) {
						if (z == this.type5Object[field.name].selectedTeeth.length - 1) {
							let t = this.type5Object[field.name].selectedTeeth[z];
							let s = this.type5Object[field.name].surfaces[z] ?? [];
							type5String += `${t}${s.join("")}`;
						} else {
							let t = this.type5Object[field.name].selectedTeeth[z];
							let s = this.type5Object[field.name].surfaces[z] ?? [];
							type5String += `${t}${s.join("")}, `;
						}
					}
					this.noteTemplate = this.noteTemplate.replaceAll("~" + field.name + "~", `<mark>${type5String.toString()}${extra_valuetext ? ' ' + extra_valuetext : ""}</mark>`);
				}
			}			
		},

		// used to open modal containing surfaces
		openSurfacesModal(evt) {
			var VPWH = [];                  // view port width / height
			var intVPW, intVPH;             // view port width / height
			var intCoordX = evt.clientX;
			var intCoordY = evt.clientY;    // distance from click point to view port top
			var intDistanceScrolledUp = document.body.scrollTop;
			// distance the page has been scrolled up from view port top
			var intPopupOffsetTop = intDistanceScrolledUp + intCoordY;
			// add the two for total distance from click point y to top of page

			var intDistanceScrolledLeft = document.body.scrollLeft;
			var intPopupOffsetLeft = intDistanceScrolledLeft + intCoordX;

			// VPWH = [window.innerWidth, (window.innerHeight as )];    // view port Width/Height
			// intVPW = VPWH[0];
			// intVPH = VPWH[1];
			(this.surfacesModal as any).show(); //surfaces_modal
			// let popup = document.getElementById("surfaces_modal") as HTMLDivElement;
			//
			// popup.style.position = 'absolute';
			// // if not display: block, .offsetWidth & .offsetHeight === 0
			// popup.style.display = 'block';
			// popup.style.zIndex = '10100';
			//
			// if ( intCoordX > intVPW/2 ) { intPopupOffsetLeft -= popup.offsetWidth; }
			// // if x is in the right half of the viewport, pull popup left by its width
			// if ( intCoordY > intVPH/2 ) { intPopupOffsetTop -= popup.offsetHeight; }
			// // if y is in the bottom half of view port, pull popup up by its height
			//
			// popup.style.top = intPopupOffsetTop + 'px';
			// popup.style.left = intPopupOffsetLeft + 'px';

		},

		// used to close modal containing surfaces
		closeSurfacesModal() {
			(this.surfacesModal as any).hide();
			this.instantPreview() // added this here because whenever modal closes it should be when user is done thus updating values
		},

		//used to add a surface for a specific tooth for a specific token
		addSurfaceForTooth(toothNumber, surface, name) {
			let toothSelect = this.type5Object[name];
			for (let i = 0; i < toothSelect.selectedTeeth.length; i++) {
				const element = toothSelect.selectedTeeth[i];
				if (element == toothNumber) {
					if (!toothSelect.surfaces[i].find((x) => x == surface)) {
						toothSelect.surfaces[i].push(surface);
					} else {
						let targetIndex = toothSelect.surfaces[i].indexOf(surface);
						toothSelect.surfaces[i].splice(targetIndex, 1);
					}
				}
			}
		},

		//used to add a tooth for a specific token then opens the tooth surface modal if type needs surfaces
		selectTooth(e, teethNumber, type, name) {			
			let endingToothNumber = teethNumber.toString().split("")[1];
			let toothSelect = this.type5Object[name];
			console.log(toothSelect, teethNumber);
			if (!toothSelect.selectedTeeth) {
				toothSelect.selectedTeeth = [];
				toothSelect.selectedTeeth.push(teethNumber);
				toothSelect.surfaces = [];
				e.target.classList.remove("btn-secondary");
				e.target.classList.add("btn-primary");
				if (endingToothNumber == 4 || endingToothNumber == 5 || endingToothNumber == 6 || endingToothNumber == 7 || endingToothNumber == 8 || endingToothNumber == 1 || endingToothNumber == 2 || endingToothNumber == 3) {
					toothSelect.surfaces.push([]);
					if (type == 'Tooth surface select') {
						this.openSurfacesModal(e);
					} else {
						this.instantPreview()
					}
				} else {
					this.instantPreview()
				}
			} else {
				//check if is in selected
				let remove =  toothSelect.selectedTeeth.find((x) => x === teethNumber);
				if (remove) {
					for (let i = 0; i <  toothSelect.selectedTeeth.length; i++) {
						const teethNum =  toothSelect.selectedTeeth[i];
						if (teethNum === teethNumber) {
							toothSelect.selectedTeeth.splice(i, 1);
							toothSelect.surfaces.splice(i, 1);
							e.target.classList.remove("btn-primary");
							e.target.classList.add("btn-secondary");
							this.instantPreview();
							return;
						}
					}
				} else {
					//select this tooth. but what if its NSF.. then we have to deselect everything else
					if (teethNumber === 'NSF' || teethNumber === 'GEN'){
						toothSelect.selectedTeeth = [];
						toothSelect.surfaces = [];
					} else { //deselect NSF if selected
						for (let i = toothSelect.selectedTeeth.length - 1; i >= 0; i--) {
							if (toothSelect.selectedTeeth[i] === 'NSF' || toothSelect.selectedTeeth[i] === 'GEN') {
								toothSelect.selectedTeeth.splice(i, 1);
							}
						}
						
					}
					toothSelect.selectedTeeth.push(teethNumber);
					e.target.classList.remove("btn-secondary");
					e.target.classList.add("btn-primary");
					if (endingToothNumber == 4 || endingToothNumber == 5 || endingToothNumber == 6 || endingToothNumber == 7 || endingToothNumber == 8 || endingToothNumber == 1 || endingToothNumber == 2 || endingToothNumber == 3) {
						toothSelect.surfaces.push([]);
						if (type == 'Tooth surface select') {
							this.openSurfacesModal(e);
						} else {
							this.instantPreview();
						}
					} else {
						this.instantPreview()
					}
				}
			}
		},


	},
});
