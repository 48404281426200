
import { defineComponent, reactive, computed } from "vue";
import ApiService from "@/core/services/ApiService";
import useValidate from "@vuelidate/core";
import { required, helpers, sameAs } from "@vuelidate/validators";

interface GroupFormState {
  name: string;
}
export default defineComponent({
  props: {
    edit: { type: Boolean, required: true },
    groupToEdit: { type: Object, required: false },
  },
  data() {
    return {
      selectedIcon: "",
      colors: [
        "#009ef7",
        "#f1416c",
        "#50cd89",
        "#ff9100",
        "#6253ff",
        "#f700ce",
        "#00bedc",
        "#dbdbdb",
        "#51f700",
        "#1a6792",
        "#7d1b33",
        "#000000",
      ],
      pickedColor: "",
    };
  },
  mounted(){
    if (this.edit) {
      this.state.name = (this.groupToEdit as any).name;
      this.pickedColor = (this.groupToEdit as any).background;
      this.selectedIcon = (this.groupToEdit as any).icon;
    }
  },
  setup() {
    let state: GroupFormState = reactive({
      name: "",
    });
    const rules = computed(() => {
      return {
        name: {
          required: helpers.withMessage("Group name required", required),
        },
      };
    });
    let v$ = useValidate(rules, state);

    return {
      v$,
      state,
    };
  },
  methods: {
    selectColor(color) {
      this.pickedColor = color;
      console.log(color);
    },
    selectGroupIcon(e, icon) {
      this.selectedIcon = icon;
    },
    async submit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        console.log("saving grp");

        try {
          let body = {};
          if (this.edit) {
            body = {
              templateGroupId: (this.groupToEdit as any).templateGroupId,
              name: this.state.name,
              icon: this.selectedIcon,
              background: this.pickedColor,
              order: 0,
              hidden: false,
            };
          }else{
            body = {
              name: this.state.name,
              icon: this.selectedIcon,
              background: this.pickedColor,
              order: 0,
              hidden: false,
            };
          }
          let res = await ApiService.postData("Template/Group", body, {} );
          if (res.status === 200) {                     
            this.$emit("updateGroups", res.data);
          }
        } catch (e) {
          console.error(e);
        }
      }
    },
  },
});
