
import { defineComponent } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import Subscriptions from "@/components/Stripe/Subscriptions.vue";

export default defineComponent({
  name: "SetupSubscription",
  props: {},
  components: {
    Subscriptions,
  },
  data() {
    return {};
  },
  mounted() {
    setCurrentPageTitle("Choose Your Plan");
  },
  methods: {},
});
